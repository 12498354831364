import scss from "./index.module.scss";

const Loader = () => {
  return (
    <div className={scss.loaderContainer}>
      <i className={scss.loaderIcon}></i>
    </div>
  );
};

export default Loader;
