import React, { Suspense } from "react";
import Loader from "./components/Loader";
import "./App.css";

const Routing = React.lazy(() => import("./Routing"));

const App = () => {
  return (
    <>
      <Suspense fallback={<Loader/>}>
        <Routing/>
      </Suspense>
    </>
  );
};

export default App;
